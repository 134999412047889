import $ from 'jquery'
import 'holderjs'
import 'application'

$(function () {
  $('[data-toggle="popover"]').popover()
  if ($('#current_input').length) {
    $('html, body').animate({
      scrollTop: $('#current_input').offset().top - 100
    })
  }
  if ($('#notificationModal').length) {
    $('#notificationModal').modal("show")
  }
})
